import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function CloudDownloadModal({ show, onSubmit, onDismiss }) {
  const [fileName, setFileName] = useState("");
  return (
    <Modal centered show={show} onHide={onDismiss}>
      <Modal.Header>
        <Modal.Title>Buluttan Geri Yükleme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Yedek Kodunuzu Giriniz"
          className="text-center"
          size="lg"
          value={fileName}
          maxLength={8}
          onChange={(e) => setFileName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onSubmit(fileName)} disabled={!fileName.trim()}>
          Tamam
        </Button>
        <Button variant="secondary" onClick={onDismiss}>
          İptal
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
