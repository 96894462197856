import { Row, Col, Button, Tab, Form, Table } from "react-bootstrap";
import {
  FaPlusCircle,
  FaTrash,
  FaAngleDoubleDown,
  FaAngleDoubleRight,
  FaSyncAlt,
  FaLock,
  FaLockOpen
} from "react-icons/fa";
import { AiOutlineClear, AiOutlineFileExcel } from "react-icons/ai";
import { Field, FieldArray } from "formik";
import showModal from "@prezly/react-promise-modal";
import { daysShortUppercase } from "../../providers/Constants";
import DeletePlaceModal from "../../components/settings/DeletePlaceModal";

function RBTextArea(props) {
  return <Form.Control as="textarea" {...props} />;
}

export default function MonitoringTasksTab({ formik }) {
  const {
    setFieldValue,
    errors,
    touched,
    values,
    values: { places },
    setValues
  } = formik;

  const rotateTasksUpToDown = (day) => {
    const unLockedPlaces = places.filter((p) => !p.locked);
    const unLockedMonitoringTasks = day
      ? unLockedPlaces.map((p) => p.monitoringTasks[day])
      : unLockedPlaces.map((p) => p.monitoringTasks);
    unLockedMonitoringTasks.unshift(unLockedMonitoringTasks.pop());
    unLockedPlaces.forEach((up, i) => {
      const placeIndex = places.findIndex((p) => p.name === up.name);
      if (placeIndex > -1) {
        if (day) {
          places[placeIndex].monitoringTasks[day] = unLockedMonitoringTasks[i];
        } else {
          places[placeIndex].monitoringTasks = unLockedMonitoringTasks[i];
        }
      }
    });
    setValues({ ...values });
  };

  const rotateTasksLeftToRight = (placeIndex) => {
    const mt = places[placeIndex].monitoringTasks;
    mt.unshift(mt.pop());
    setValues({ ...values });
  };

  const pasteFromExcelToTasks = () => {
    navigator.clipboard.readText().then((copied) => {
      copied
        .trim()
        .split(/\r\n/)
        .slice(0, places.length)
        .forEach((line, i) => {
          const parsedLine = line.split(/\t/).slice(0, 7);
          places[i].monitoringTasks.fill("").splice(0, parsedLine.length, ...parsedLine);
        });
      setValues({ ...values });
    });
  };

  const clearTasks = () => {
    places.forEach((p) => p.monitoringTasks.fill(""));
    setValues({ ...values });
  };

  const deletePlace = (item, index) => {
    showModal(({ show, onSubmit, onDismiss }) => (
      <DeletePlaceModal item={item} show={show} onSubmit={onSubmit} onDismiss={onDismiss} />
    )).then((result) => {
      if (result) {
        places.splice(index, 1);
        setValues({ ...values });
      }
    });
  };

  return (
    <Tab.Pane eventKey="monitoring-tasks">
      <Row>
        <Col>
          <Table size="sm" striped bordered hover id="monitoring-tasks-table">
            <thead>
              <tr>
                <th>Nöbet Yeri</th>
                {daysShortUppercase.map((dayName, dayIndex) => (
                  <th key={dayName}>
                    <div className="d-flex px-2 align-items-center justify-content-between">
                      {dayName}{" "}
                      <Button
                        size="sm"
                        variant="warning"
                        className="p-1"
                        title="Sütundaki nöbetleri aşağı yönde döndür"
                        onClick={() => rotateTasksUpToDown(dayIndex)}
                      >
                        <FaAngleDoubleDown />
                      </Button>
                    </div>
                  </th>
                ))}
                <th>İşlemler</th>
              </tr>
            </thead>
            <FieldArray
              name="places"
              render={(arrayHelpers) => (
                <>
                  <tbody>
                    {places.map((place, placeIndex) => (
                      <tr key={placeIndex}>
                        <th scope="row">
                          <Field
                            as={RBTextArea}
                            className="text-sm-left font-weight-bold"
                            name={`places[${placeIndex}].name`}
                            isInvalid={errors.places && touched.places && errors.places[placeIndex]}
                          />
                          {errors.places && touched.places && errors.places[placeIndex] ? (
                            <Form.Control.Feedback type="invalid">
                              {errors?.places[placeIndex]?.name}
                            </Form.Control.Feedback>
                          ) : null}
                        </th>
                        {place.monitoringTasks.map((person, dayIndex) => (
                          <td key={dayIndex}>
                            <Field
                              as="textarea"
                              className="form-control text-sm-left"
                              name={`places[${placeIndex}].monitoringTasks[${dayIndex}]`}
                            />
                          </td>
                        ))}
                        <td className="text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              size="sm"
                              variant={place.locked ? "danger" : "success"}
                              className="me-2 p-1"
                              title={place.locked ? "Satır Kilidini Aç" : "Satırı Kilitle"}
                              onClick={() => setFieldValue(`places[${placeIndex}].locked`, !places[placeIndex].locked)}
                            >
                              {place.locked ? <FaLock color="white" /> : <FaLockOpen color="white" />}
                            </Button>
                            <Button
                              size="sm"
                              variant="warning"
                              className="me-2 p-1"
                              title="Satırdaki nöbetleri sağ yönde döndür"
                              onClick={() => rotateTasksLeftToRight(placeIndex)}
                            >
                              <FaAngleDoubleRight />
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              className="p-1"
                              title="Sil"
                              onClick={() => deletePlace(place, placeIndex)}
                            >
                              <FaTrash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="position-sticky fixed-bottom bg-lightgray">
                    <tr>
                      <th colSpan="9">
                        <Button
                          variant="primary"
                          className="d-inline-flex align-items-center me-2"
                          onClick={() => {
                            arrayHelpers.push({
                              name: "",
                              monitoringTasks: ["", "", "", "", "", "", ""]
                            });
                            const mtTable = document.getElementById("monitoring-tasks-table");
                            mtTable.scrollIntoView(false);
                          }}
                        >
                          <FaPlusCircle />
                          <span className="ms-2">Yeni Nöbet Yeri Ekle</span>
                        </Button>
                        <Button
                          variant="warning"
                          className="d-inline-flex align-items-center me-2"
                          onClick={() => rotateTasksUpToDown()}
                        >
                          <FaSyncAlt
                            style={{
                              transform: "rotate(90deg)"
                            }}
                          />
                          <span className="ms-2">Nöbetleri Döndür</span>
                        </Button>
                        <Button
                          variant="success"
                          className="d-inline-flex align-items-center me-2"
                          onClick={pasteFromExcelToTasks}
                        >
                          <AiOutlineFileExcel />
                          <span className="ms-2">Excel'den Yapıştır</span>
                        </Button>
                        <Button variant="danger" className="d-inline-flex align-items-center me-2" onClick={clearTasks}>
                          <AiOutlineClear />
                          <span className="ms-2">Temizle</span>
                        </Button>
                      </th>
                    </tr>
                  </tfoot>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
