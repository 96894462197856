import { useState } from "react";
import { Row, Col, Button, Tab, Card, Alert } from "react-bootstrap";
import { FaDownload, FaUpload, FaFile } from "react-icons/fa";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { MdCloud, MdCloudDownload, MdCloudUpload } from "react-icons/md";
import localForage from "localforage";
import showModal from "@prezly/react-promise-modal";
import toast from "cogo-toast";
import CloudUploadModal from "../../components/settings/CloudUploadModal";
import CloudDownloadModal from "../../components/settings/CloudDownloadModal";

export default function ImportExportTab({ formik, offlineMode }) {
  const { setValues } = formik;
  const [cloudUploading, setCloudUploading] = useState(false);
  const [cloudDownloading, setCloudDownloading] = useState(false);

  const settings2JSON = () =>
    new Promise((resolve, reject) => {
      Promise.all([
        localForage.getItem("settings"),
        localForage.getItem("lessonTimes"),
        localForage.getItem("places"),
        localForage.getItem("classRooms"),
        localForage.getItem("firstInstall")
      ])
        .then((result) => {
          const [settings, lessonTimes, places, classRooms, firstInstall] = result;
          delete settings.sliderFolder;
          resolve(JSON.stringify({ settings, lessonTimes, places, classRooms, firstInstall }));
        })
        .catch((err) => reject(err));
    });

  const restoreSettings = (fetchedSettings) => {
    Promise.all(Object.keys(fetchedSettings).map((key) => localForage.setItem(key, fetchedSettings[key]))).then(() => {
      setValues({ ...fetchedSettings });
      toast.success("Ayarlar geri yüklendi.");
    });
  };

  const cloudExportSettings = () => {
    setCloudUploading(true);
    settings2JSON().then((jsonString) => {
      fetch("https://app.lcdpano.net/cloud-save/index.php", { method: "POST", body: jsonString })
        .then((result) => {
          result.json().then(({ fileName }) => {
            showModal(({ show, onSubmit, onDismiss }) => (
              <CloudUploadModal code={fileName} show={show} onDismiss={onDismiss} />
            )).then();
          });
        })
        .catch((err) => {
          console.error(err);
          toast.error("Yedek karşıya yüklenirken hata oluştu.");
        })
        .finally(() => {
          setTimeout(() => {
            setCloudUploading(false);
          }, 30000);
        });
    });
  };

  const cloudImportSettings = () => {
    showModal(({ show, onSubmit, onDismiss }) => (
      <CloudDownloadModal show={show} onSubmit={onSubmit} onDismiss={onDismiss} />
    )).then((fileName) => {
      if (fileName) {
        setCloudDownloading(true);
        fetch(`https://app.lcdpano.net/cloud-save/files/${fileName}.json`)
          .then((result) => {
            result.json().then(restoreSettings);
          })
          .catch((err) => {
            console.error(err);
            toast.error("Yedek indirilirken hata oluştu.");
          })
          .finally(() => {
            setCloudDownloading(false);
          });
      }
    });
  };

  const fileExportSettings = () => {
    settings2JSON().then((jsonString) => {
      const exporter = document.createElement("a");
      exporter.href = URL.createObjectURL(new Blob([jsonString], { type: "application/json" }));
      exporter.download = "lcd-pano.yedek.json";
      exporter.click();
      setTimeout(() => {
        exporter.remove();
      }, 100);
    });
  };

  const fileImportSettings = () => {
    const importer = document.createElement("input");
    importer.type = "file";
    importer.addEventListener("change", () => {
      const file = Array.from(importer.files)[0];
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        restoreSettings(JSON.parse(e.target.result.toString()));
      });
      reader.readAsText(file);
    });
    importer.click();
    setTimeout(() => {
      importer.remove();
    }, 100);
  };

  return (
    <Tab.Pane eventKey="import-export">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <FaFile className="me-2" />
              Dosyaya Yedekle/Geri Yükle
            </Card.Header>
            <Card.Body>
              <Button
                variant="primary"
                type="button"
                onClick={fileExportSettings}
                className="d-inline-flex align-items-center me-2"
              >
                <FaDownload className="me-2" />
                Yedek İndir
              </Button>
              <Button
                variant="success"
                type="button"
                onClick={fileImportSettings}
                className="d-inline-flex align-items-center"
              >
                <FaUpload className="me-2" />
                Yedekten Geri Yükle
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <MdCloud className="me-2" />
              Buluta Yedekle/Geri Yükle{" "}
              {offlineMode && <IoCloudOfflineOutline className="text-danger ms-2" title="Bağlantı yok" />}
            </Card.Header>
            <Card.Body>
              <Button
                variant="primary"
                type="button"
                onClick={cloudExportSettings}
                className="d-inline-flex align-items-center me-2"
                disabled={cloudUploading || offlineMode}
              >
                <MdCloudUpload className="me-2" />
                Buluta Yedekle
              </Button>
              <Button
                variant="success"
                type="button"
                onClick={cloudImportSettings}
                className="d-inline-flex align-items-center"
                disabled={cloudDownloading || offlineMode}
              >
                <MdCloudDownload className="me-2" />
                Buluttan Geri Yükle
              </Button>
              {cloudUploading && (
                <Alert variant="info" className="mt-4">
                  Tekrar yedekleyebilmek için lütfen 30 sn. bekleyiniz.
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
