import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import { Spinner } from "react-bootstrap";
import localForage from "localforage";
import InitialDB from "../assets/data/initial-database.json";
import toast from "cogo-toast";

export const DatabaseContext = React.createContext(null);

export default function DatabaseProvider({ children }) {
  const [DB, setDB] = useState(null);

  useEffect(() => {
    seedDatabase()
      .then(() => {
        localForage.keys().then((keys) => {
          Promise.all(keys.map((key) => localForage.getItem(key))).then((result) => {
            const dbResult = {};
            keys.forEach((key, i) => (dbResult[key] = result[i]));

            let hasSettingsUpdate = false;
            Object.keys(InitialDB.settings).forEach((key) => {
              if (dbResult.settings[key] === undefined) {
                hasSettingsUpdate = true;
                dbResult.settings[key] = InitialDB.settings[key];
              }
            });

            let hasLessonTimesUpdate = false;
            if (!Array.isArray(dbResult.lessonTimes[0])) {
              const newLessonTimes = [...dbResult.lessonTimes];
              dbResult.lessonTimes = [
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes]
              ];
              hasLessonTimesUpdate = true;
            } else if (Array.isArray(dbResult.lessonTimes[0]) && dbResult.lessonTimes.length !== 14) {
              const newLessonTimes = dbResult.lessonTimes[0];
              dbResult.lessonTimes = [
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes],
                [...newLessonTimes]
              ];
              hasLessonTimesUpdate = true;
            }

            let hasPlacesUpdate = false;
            if (dbResult.places.some((p) => p.locked === undefined)) {
              dbResult.places.forEach((p) => {
                if (p.locked === undefined) {
                  p.locked = false;
                }
              });
              hasPlacesUpdate = true;
            }

            let hasClassRoomsUpdate = false;
            if (dbResult.classRooms.some((c) => c.isAfternoon === undefined)) {
              dbResult.classRooms.forEach((c) => {
                if (c.isAfternoon === undefined) {
                  c.isAfternoon = false;
                }
              });
              hasClassRoomsUpdate = true;
            }

            if (hasSettingsUpdate || hasLessonTimesUpdate || hasClassRoomsUpdate || hasPlacesUpdate) {
              const promises = [];
              if (hasSettingsUpdate) {
                promises.push(localForage.setItem("settings", { ...dbResult.settings }));
              }
              if (hasLessonTimesUpdate) {
                promises.push(localForage.setItem("lessonTimes", [...dbResult.lessonTimes]));
              }
              if (hasClassRoomsUpdate) {
                promises.push(localForage.setItem("classRooms", [...dbResult.classRooms]));
              }
              if (hasPlacesUpdate) {
                promises.push(localForage.setItem("places", [...dbResult.places]));
              }

              Promise.all(promises).then(() => {
                setDB({ ...dbResult });
              });
            } else {
              setDB({ ...dbResult });
            }
          });
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  return DB ? (
    <DatabaseContext.Provider value={{ DB, setDB }}>{children}</DatabaseContext.Provider>
  ) : (
    <div className="d-flex flex-fill justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={Logo} className="d-flex animate__animated animate__flipInX animate__slow" alt="Logo" />
        <div className="animate__animated animate__fadeIn animate__delay-2s">
          <Spinner animation="border" variant="secondary" />
        </div>
      </div>
    </div>
  );
}

async function seedDatabase() {
  return new Promise((resolve, reject) => {
    localForage.getItem("firstInstall").then((firstInstall) => {
      if (!firstInstall) {
        console.log("DB installing");
        Promise.all(Object.keys(InitialDB).map((key) => localForage.setItem(key, InitialDB[key])))
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject("DB oluşturulurken bir hata oluştu");
          });
      } else resolve();
    });
  });
}
