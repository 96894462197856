import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Tab, Form } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DatabaseContext } from "../providers/DatabaseProvider";
import { FormikProvider, useFormik } from "formik";
import { WeatherContext } from "../providers/WeatherProvider";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import localForage from "localforage";
import { useHotkeys } from "react-hotkeys-hook";
import ValidationSchema from "../providers/ValidationSchema";
import { OnlineStatusContext } from "../providers/OnlineStatusProvider";
import TopBar from "../components/settings/TopBar";
import GeneralTab from "./settings-subpages/GeneralTab";
import LessonTimesTab from "./settings-subpages/LessonTimesTab";
import LessonProgramTab from "./settings-subpages/LessonProgramTab";
import MonitoringTasksTab from "./settings-subpages/MonitoringTasksTab";
import ImportExportTab from "./settings-subpages/ImportExportTab";
import SliderTab from "./settings-subpages/SliderTab";
import CustomModuleTab from "./settings-subpages/CustomModuleTab";

const Settings = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const location = useLocation();

  const { DB, setDB } = useContext(DatabaseContext);
  const { offlineMode } = useContext(OnlineStatusContext);
  const { currentConditions } = useContext(WeatherContext);

  const [activeTab, setActiveTab] = useState(page || "general");
  const [activeLessonTab, setActiveLessonTab] = useState("lessonTimesTab0");
  const [editorStateLeftBack, setEditorStateLeftBack] = useState(
    EditorState.createWithContent(convertFromRaw(DB.settings.customModuleContentLeftBack))
  );
  const [editorStateLeftFront, setEditorStateLeftFront] = useState(
    EditorState.createWithContent(convertFromRaw(DB.settings.customModuleContentLeftFront))
  );
  const [editorStateRightBack, setEditorStateRightBack] = useState(
    EditorState.createWithContent(convertFromRaw(DB.settings.customModuleContentRightBack))
  );

  const formik = useFormik({
    initialValues: { ...DB },
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const { settings, lessonTimes, places, classRooms } = values;

      values.settings.customModuleContentLeftBack = convertToRaw(editorStateLeftBack.getCurrentContent());
      values.settings.customModuleContentLeftFront = convertToRaw(editorStateLeftFront.getCurrentContent());
      values.settings.customModuleContentRightBack = convertToRaw(editorStateRightBack.getCurrentContent());

      if (settings.sameLessonTimesEveryday) {
        values.lessonTimes = settings.dualEducation
          ? [
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1],
              lessonTimes[0],
              lessonTimes[1]
            ]
          : [
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0],
              lessonTimes[0]
            ];
      }

      Promise.all([
        localForage.setItem("settings", settings),
        localForage.setItem("places", places),
        localForage.setItem("lessonTimes", lessonTimes),
        localForage.setItem("classRooms", classRooms)
      ]).then(() => {
        setSubmitting(false);
        setDB({ ...values });
        navigate("/");
      });
    }
  });

  const { values, handleSubmit, isSubmitting, errors } = formik;

  useEffect(() => {
    if (activeTab === "lesson-times") {
      setActiveLessonTab("lessonTimesTab0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.settings.sameLessonTimesEveryday]);

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path[1] === "settings" && path[2] !== activeTab) {
      setActiveTab(path[2] ?? "general");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useHotkeys("ctrl+s", (e) => {
    document.getElementById("saveButton").click();
    e.preventDefault();
  });

  const focusToErrors = () => {
    if (Array.isArray(errors.settings) && errors.settings.length > 0) {
      if (activeTab !== "general") {
        navigate("/settings/general");
      }
    } else if (Array.isArray(errors.places) && errors.places.length > 0) {
      if (activeTab !== "monitoring-tasks") {
        navigate("/settings/monitoring-tasks");
      }
    } else if (Array.isArray(errors.lessonTimes) && errors.lessonTimes.length > 0) {
      const dayIndex = errors.lessonTimes.findIndex((error) => error?.length > 0);
      if (dayIndex > -1) {
        if (activeTab !== "lesson-times") {
          navigate("/settings/lesson-times");
        }
        setActiveLessonTab(`lessonTimesTab${dayIndex}`);
      }
    }
  };

  return (
    <Container fluid style={{ overflowY: "scroll" }}>
      <TopBar navigate={navigate} activeTab={activeTab} isSubmitting={isSubmitting}></TopBar>
      <FormikProvider value={formik}>
        <Form
          onSubmit={(e) => {
            focusToErrors();
            handleSubmit(e);
          }}
          id="settingsForm"
        >
          <Tab.Container id="settings-tabs" activeKey={activeTab}>
            <Row className="m-0 h-100">
              <Col className="bg-lightgray p-3">
                <Tab.Content>
                  <GeneralTab formik={formik} offlineMode={offlineMode} currentConditions={currentConditions} />
                  <LessonTimesTab
                    formik={formik}
                    activeLessonTab={activeLessonTab}
                    setActiveLessonTab={setActiveLessonTab}
                  />
                  <MonitoringTasksTab formik={formik} />
                  <SliderTab formik={formik} />
                  <CustomModuleTab eventKey="custom-module-left-back" id="LeftBack" editorState={editorStateLeftBack} setEditorState={setEditorStateLeftBack} />
                  <CustomModuleTab eventKey="custom-module-left-front" id="LeftFront" editorState={editorStateLeftFront} setEditorState={setEditorStateLeftFront} />
                  <CustomModuleTab eventKey="custom-module-right-back" id="RightBack" editorState={editorStateRightBack} setEditorState={setEditorStateRightBack} />
                  <LessonProgramTab formik={formik} activeTab={activeTab} />
                  <ImportExportTab formik={formik} offlineMode={offlineMode} />
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Form>
      </FormikProvider>
    </Container>
  );
};

export default Settings;
