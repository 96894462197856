import React, { useContext, useEffect, useState } from "react";
import conditions from "../assets/data/weather-conditions.json";
import cities from "../assets/data/weather-cities.json";
import { OnlineStatusContext } from "./OnlineStatusProvider";

export const WeatherContext = React.createContext(null);

const weatherXmlUrl = "https://www.mgm.gov.tr/FTPDATA/analiz/SonDurumlarTumu.xml";

const fetchAllCurrentConditions = () => {
  return new Promise((resolve, reject) => {
    fetch(weatherXmlUrl)
      .then((r) => r.text())
      .then((rText) => {
        const stationsXML = Array.prototype.slice.call(
          new DOMParser().parseFromString(rText, "text/xml").getElementsByTagName("Merkezler")
        );

        stationsXML.forEach((m) => {
          const cityNumber = parseInt(m.getElementsByTagName("ilP")[0].textContent);
          const districtName = m.getElementsByTagName("ilcesi")[0].textContent;
          const stationName = m.getElementsByTagName("istadi")[0].textContent;
          const conditionName = m.getElementsByTagName("Durum")[0].textContent;
          const temperature = parseFloat(m.getElementsByTagName("tmp")[0].textContent.replace(",", "."));
          const city = cities.find((i) => i.cityNumber === cityNumber);
          if (city.stations === null) city.stations = [];
          city.stations.push({
            districtName: districtName === "MERKEZ" ? city.cityName : districtName,
            stationName: stationName,
            temperature: temperature,
            condition:
              conditionName === ""
                ? null
                : {
                    name: conditionName,
                    code: conditions[conditionName]
                  }
          });
        });

        cities.forEach((i) => {
          if (i.stations) {
            i.stations.sort((a, b) => a.districtName.localeCompare(b.districtName));
            i.stations.sort((a, b) => a.stationName.localeCompare(b.stationName));
          }
        });
        resolve(cities);
      })
      .catch((err) => reject(err));
  });
};

export default function WeatherProvider({ children }) {
  const { setOfflineMode } = useContext(OnlineStatusContext);
  const [currentConditions, setCurrentConditions] = useState();

  const refreshCurrentConditions = () => {
    fetchAllCurrentConditions()
      .then((c) => {
        setCurrentConditions(c);
        setOfflineMode(false);
      })
      .catch((err) => {
        console.error(err);
        setOfflineMode(true);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshCurrentConditions, []);

  return (
    <WeatherContext.Provider value={{ currentConditions, refreshCurrentConditions }}>
      {children}
    </WeatherContext.Provider>
  );
}
