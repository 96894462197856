import { Row, Col, Tab, Form } from "react-bootstrap";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { ErrorMessage, Field } from "formik";
import weatherCities from "../../assets/data/weather-cities.json";

function RBSelect(props) {
  return <Form.Control as="select" {...props} />;
}

export default function GeneralTab({ formik, offlineMode, currentConditions }) {
  const {
    values: { settings },
    setFieldValue,
    errors,
    touched,
    handleChange
  } = formik;

  return (
    <Tab.Pane eventKey="general">
      <Row>
        <Col>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Okul Adı
            </Form.Label>
            <Col>
              <Field as={Form.Control} name="settings.schoolName" placeholder="Okulunuzun adını giriniz" />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Kayan Yazı Metni
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Control}
                type="text"
                name="settings.marqueeText"
                placeholder="Kayan yazı metnini giriniz"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Kayan Yazı Hızı
            </Form.Label>
            <Col xs={5} sm={6} lg={7} className="d-flex align-items-center">
              <Field as={Form.Range} min={1} max={10} name="settings.marqueeSpeed" />
            </Col>
            <Col xs={2} sm={1}>
              {settings.marqueeSpeed}
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Sağ-Sol Bölüm Süreli Gösterim Hızı
            </Form.Label>
            <Col xs={5} sm={6} lg={7} className="d-flex align-items-center">
              <Field as={Form.Range} min={5} max={30} name="settings.flipperInterval" />
            </Col>
            <Col xs={2} sm={1}>
              {settings.flipperInterval}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Sol Bölüm Aktif Modüller
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Check}
                type="checkbox"
                inline
                id="hallMonitorsActive"
                name="settings.activeModules"
                value="hall-monitors"
                label="Sol Ön Modül"
                isInvalid={
                  errors.settings &&
                  touched.settings &&
                  errors.settings.activeModules &&
                  !settings.activeModules.includes("hall-monitors") &&
                  !settings.activeModules.includes("custom-module")
                }
                checked={settings.activeModules.includes("hall-monitors")}
              />
              <Field
                as={Form.Check}
                type="checkbox"
                inline
                id="customModuleActive"
                name="settings.activeModules"
                value="custom-module"
                label="Sol Arka Modül"
                isInvalid={
                  errors.settings &&
                  touched.settings &&
                  errors.settings.activeModules &&
                  !settings.activeModules.includes("hall-monitors") &&
                  !settings.activeModules.includes("custom-module")
                }
                checked={settings.activeModules.includes("custom-module")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Sağ Bölüm Aktif Modüller
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Check}
                type="checkbox"
                inline
                id="trtHaberWeatherActive"
                name="settings.activeModules"
                value="trt-haber-weather"
                label="Hava Durumu & TRT Haber"
                isInvalid={
                  errors.settings &&
                  touched.settings &&
                  errors.settings.activeModules &&
                  !settings.activeModules.includes("trt-haber-weather") &&
                  !settings.activeModules.includes("time-table")
                }
                checked={settings.activeModules.includes("trt-haber-weather")}
              />
              <Field
                as={Form.Check}
                type="checkbox"
                inline
                id="timeTableActive"
                name="settings.activeModules"
                value="time-table"
                label="Sağ Arka Modül"
                isInvalid={
                  errors.settings &&
                  touched.settings &&
                  errors.settings.activeModules &&
                  !settings.activeModules.includes("trt-haber-weather") &&
                  !settings.activeModules.includes("time-table")
                }
                checked={settings.activeModules.includes("time-table")}
              />
              <ErrorMessage
                name="settings.activeModules"
                render={(msg) => <div className="text-danger mt-3">{msg}</div>}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              TRT Haber Kategorisi{" "}
              {offlineMode && <IoCloudOfflineOutline className="text-danger ms-2" title="Bağlantı yok" />}
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field as={RBSelect} name="settings.trtHaberCategory" disabled={offlineMode}>
                <option value="mansetler-m">Manşetler</option>
                <option value="gundem-1">Gündem</option>
                <option value="turkiye-2">Türkiye</option>
                <option value="dunya-4">Dünya</option>
                <option value="spor-5">Spor</option>
                <option value="saglik-6">Sağlık</option>
                <option value="ekonomi-7">Ekonomi</option>
                <option value="yasam-8">Yaşam</option>
                <option value="kultur-sanat-9">Kültür-Sanat</option>
                <option value="bilim-teknik-10">Bilim-Teknik</option>
                <option value="cevre-11">Çevre</option>
                <option value="egitim-12">Eğitim</option>
                <option value="magazin-19">Magazin</option>
              </Field>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Hava Durumu İli{" "}
              {offlineMode && <IoCloudOfflineOutline className="text-danger ms-2" title="Bağlantı yok" />}
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field as={RBSelect} className="custom-select" name="settings.weatherCity" disabled={offlineMode}>
                {weatherCities.map((c) => (
                  <option value={c.cityNumber} key={c.cityNumber}>
                    [{`0${c.cityNumber}`.slice(-2)}] {c.cityName}
                  </option>
                ))}
              </Field>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Hava Durumu İstasyonu{" "}
              {offlineMode && <IoCloudOfflineOutline className="text-danger ms-2" title="Bağlantı yok" />}
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={RBSelect}
                className="custom-select"
                name="settings.weatherStation"
                onChange={(e) => {
                  setFieldValue("settings.weatherDisplayText", e.currentTarget.value);
                  handleChange(e);
                }}
                disabled={offlineMode}
              >
                {currentConditions ? (
                  currentConditions
                    .find((c) => c.cityNumber === parseInt(settings.weatherCity))
                    .stations.map((s, i) => (
                      <option key={`st${i}`} value={s.stationName}>
                        {s.districtName} - {s.stationName}
                      </option>
                    ))
                ) : (
                  <option value={settings.weatherCity}>{settings.weatherStation}</option>
                )}
              </Field>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Görüntülenecek Metin{" "}
              {offlineMode && <IoCloudOfflineOutline className="text-danger ms-2" title="Bağlantı yok" />}
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Control}
                type="text"
                disabled={offlineMode}
                name="settings.weatherDisplayText"
                placeholder="Panoda görüntülenecek hava durumu
                                                                    konumunu buradan değiştirebilirsiniz."
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
