import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { MdPublishedWithChanges } from "react-icons/md";

export default function VersionPopup() {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="bottom"
      overlay={
        <Popover id="popover-version-info">
          <Popover.Header as="h6">
            <MdPublishedWithChanges className="me-2" />
            Yenilikler ve Düzeltmeler
          </Popover.Header>
          <Popover.Body>
            <h6>
              v4.0.2{" "}
              <small>
                <i>(09/01/2023)</i>
              </small>
            </h6>
            <small>
              <ul>
                <li>Sağ-sol modüller tamamen devre dışı bırakılabilir hale getirildi.</li>
              </ul>
            </small>
            <hr />
            <h6>
              v4.0.1{" "}
              <small>
                <i>(03/01/2023)</i>
              </small>
            </h6>
            <small>
              <ul>
                <li>Yedek geri yüklenirken oluşan bir sorun giderildi.</li>
              </ul>
            </small>
            <hr />
            <h6>
              v4.0.0{" "}
              <small>
                <i>(22/12/2022)</i>
              </small>
            </h6>
            <small>
              <ul>
                <li>İkili öğretim sistemi desteği getirildi.</li>
                <li>Farklı gün ve saatlere göre program hazırlanabilme eklendi.</li>
                <li>Slayt resim boyutlandırma seçenekleri eklendi.</li>
                <li>Nöbetlerin döndürülmesinde sabitleme özelliği eklendi.</li>
                <li>Bazı hatalar giderildi.</li>
                <li>Paketler güncellendi.</li>
              </ul>
            </small>
            <hr />
            <h6>
              v3.0.6{" "}
              <small>
                <i>(22/02/2022)</i>
              </small>
            </h6>
            <small>
              <ul>
                <li>Bulut tabanlı yedekleme özelliği eklendi.</li>
              </ul>
            </small>
            <hr />
            <h6>
              v3.0.5{" "}
              <small>
                <i>(20/02/2022)</i>
              </small>
            </h6>
            <small>
              <ul>
                <li>Gün değiştiğinde Nöbetçi Personel'in değişmemesi sorunu giderildi.</li>
                <li>Offline çalışmama sorunu giderildi.</li>
              </ul>
            </small>
          </Popover.Body>
        </Popover>
      }
    >
      <Badge variant="info">4.0.2</Badge>
    </OverlayTrigger>
  );
}
