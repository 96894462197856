import React, {useContext} from "react";
import {Card} from "react-bootstrap";
import TRTLogo from "../assets/images/trt-haber.png";
import {DatabaseContext} from "../providers/DatabaseProvider";

export default function TRTHaber() {

    const {DB} = useContext(DatabaseContext)

    return (
        <Card className="trt-haber module d-flex flex-fill">
            <Card.Header className="d-flex align-items-center p-2 text-center orange justify-content-center">
                <img src={TRTLogo} alt="TRT Logo" height={30}/>
            </Card.Header>
            <Card.Body className="p-1 d-flex justify-content-center align-items-center">
                <iframe sandbox="" width="100%" height="100%" title="TRT Haber"
                        src={"https://www.trthaber.com/sitene-ekle/" + DB.settings.trtHaberCategory + "/?haberSay=10&renk=a&resimler=1"}/>
            </Card.Body>
        </Card>
    )
}
