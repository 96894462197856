import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./pages/App";
import Settings from "./pages/Settings";
import DatabaseProvider from "./providers/DatabaseProvider";
import WeatherProvider from "./providers/WeatherProvider";
import isElectron from "is-electron";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import OnlineStatusProvider from "./providers/OnlineStatusProvider";
import TimerProvider from "./providers/TimerProvider";
import "./assets/fonts/fonts.css";
import "./styles.scss";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <DatabaseProvider>
      <OnlineStatusProvider>
        <WeatherProvider>
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <TimerProvider>
                    <App />
                  </TimerProvider>
                }
              />
              <Route exact path="/settings/:page" element={<Settings />} />
              <Route exact path="/settings" element={<Navigate to="/settings/general" />} />
            </Routes>
          </BrowserRouter>
        </WeatherProvider>
      </OnlineStatusProvider>
    </DatabaseProvider>
  </StrictMode>
);
if (!isElectron()) {
  serviceWorkerRegistration.register();
  reportWebVitals(console.log);
}
