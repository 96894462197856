import { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Form, Card } from "react-bootstrap";
import useDrivePicker from "react-google-drive-picker";
import { FaExternalLinkAlt, FaFolderOpen, FaSignOutAlt, FaGoogleDrive } from "react-icons/fa";
import { Field } from "formik";
import localForage from "localforage";

export default function SliderTab({ formik }) {
  const {
    values: { settings },
    setFieldValue
  } = formik;

  const [googleSignedIn, setGoogleSignedIn] = useState(false);
  const [googleDriveFiles, setGoogleDriveFiles] = useState([]);
  const [openPicker, authResponse] = useDrivePicker();

  const googleSignOut = () => {
    Promise.all([localForage.removeItem("googleToken"), localForage.removeItem("googleDriveFiles")]).then(() => {
      setGoogleSignedIn(false);
      setGoogleDriveFiles([]);
    });
  };

  const openGooglePicker = () => {
    localForage.getItem("googleToken").then((token) => {
      openPicker({
        clientId: "228953711835-hj23l5kh6ennrj67k6s2sm0p6k7v837o.apps.googleusercontent.com",
        developerKey: "AIzaSyApPDFZrUSux4wceLrT4zBWiKcE0nlgjEM",
        viewId: "DOCS_IMAGES_AND_VIDEOS",
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        token,
        locale: "tr",
        callbackFunction: (data) => {
          if (data?.docs && Array.isArray(data.docs) && data.docs.length > 0) {
            console.log(data.docs);
            localForage.setItem("googleDriveFiles", data.docs).then(() => {
              setGoogleDriveFiles(data.docs);
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    if (authResponse?.access_token) {
      localForage.setItem("googleToken", authResponse.access_token).then(() => {
        setGoogleSignedIn(true);
      });
    }
  }, [authResponse]);

  useEffect(() => {
    localForage.getItem("googleToken").then((token) => {
      setGoogleSignedIn(Boolean(token));
      localForage.getItem("googleDriveFiles").then((files) => {
        setGoogleDriveFiles(files ?? []);
      });
    });
  }, []);

  return (
    <Tab.Pane eventKey="slider">
      <Row>
        <Col>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={5} lg={4}>
              Slayt Değişim Hızı
            </Form.Label>
            <Col xs={5} sm={6} lg={7}>
              <Field as={Form.Range} min={5} max={30} name="settings.sliderInterval" />
            </Col>
            <Col xs={2} sm={1}>
              {settings.sliderInterval}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={6} lg={4}>
              Slayt Animasyonu{" "}
              <a
                href="https://caferati.me/demo/react-awesome-slider"
                target="_blank"
                rel="noopener noreferrer"
                title="Animasyonların önizlemelerini görmek için tıklayınız."
              >
                <FaExternalLinkAlt />
              </a>
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation1"
                name="settings.sliderAnimation"
                value="basic"
                label="Basic"
                checked={settings.sliderAnimation === "basic"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation2"
                name="settings.sliderAnimation"
                value="cubeAnimation"
                label="Cube"
                checked={settings.sliderAnimation === "cubeAnimation"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation3"
                name="settings.sliderAnimation"
                value="scaleOutAnimation"
                label="Scale-Out"
                checked={settings.sliderAnimation === "scaleOutAnimation"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation4"
                name="settings.sliderAnimation"
                value="fallAnimation"
                label="Fall"
                checked={settings.sliderAnimation === "fallAnimation"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation5"
                name="settings.sliderAnimation"
                value="foldOutAnimation"
                label="Fold-Out"
                checked={settings.sliderAnimation === "foldOutAnimation"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderAnimation6"
                name="settings.sliderAnimation"
                value="openAnimation"
                label="Open"
                checked={settings.sliderAnimation === "openAnimation"}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column xs={6} lg={4}>
              Slayt Resim/Video Boyutlandırma Modu
            </Form.Label>
            <Col className="d-flex align-items-center">
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderImageResizingMode1"
                name="settings.sliderImageResizingMode"
                value="cover"
                label="Kırp"
                title="Resmin genişliğe veya yüksekliğe göre oranları bozmadan boyutlandırır. Fakat fazlalık kısımları kırpar."
                checked={settings.sliderImageResizingMode === "cover"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderImageResizingMode2"
                name="settings.sliderImageResizingMode"
                value="contain"
                label="Küçült"
                title="Resmi oranlarını bozmadan küçülterek slayt alanına sığdırır. Fakat resim etrafında boş alanlar oluşabilir."
                checked={settings.sliderImageResizingMode === "contain"}
              />
              <Field
                as={Form.Check}
                type="radio"
                inline
                id="sliderImageResizingMode3"
                name="settings.sliderImageResizingMode"
                value="fill"
                label="Uzat"
                title="Resmi slayt alanını tam dolduracak şekilde uzatarak boyutlandırır. Fakat resmin oranları bozulabilir."
                checked={settings.sliderImageResizingMode === "fill"}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Col className="d-flex align-items-center">
              <Button
                variant="primary"
                className="me-4"
                onClick={() => {
                  window.showDirectoryPicker().then((dirHandle) => {
                    setFieldValue("settings.sliderFolder", dirHandle);
                  });
                }}
              >
                <FaFolderOpen className="me-2" />
                Slayt Resim Klasörünü Seç/Değiştir
              </Button>
              <Button variant="success" onClick={openGooglePicker} className="me-4">
                <FaGoogleDrive className="me-2" />
                Google Drive Üzerinden Resim Seç
              </Button>
              {googleSignedIn && (
                <Button variant="secondary" onClick={googleSignOut}>
                  <FaSignOutAlt className="me-2" />
                  Google Drive Bağlantısını Kes
                </Button>
              )}
            </Col>
          </Form.Group>
          <Row>
            {googleDriveFiles.map((file) => (
              <Col lg={1} md={2} sm={3} key={file.id}>
                <Card className="mb-4">
                  <img className="img-fluid rounded" src={`https://drive.google.com/uc?id=${file.id}`} alt="Resim" />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
