import React, {useEffect, useState} from "react"

export const OnlineStatusContext = React.createContext(null)

export default function OnlineStatusProvider({children}) {

    const [offlineMode, setOfflineMode] = useState(false)

    useEffect(() => {
        window.addEventListener('offline', () => {
            setOfflineMode(true)
        })

        window.addEventListener('online', () => {
            setOfflineMode(false)
        })

        return () => {
            window.removeEventListener('offline', () => {
                setOfflineMode(true)
            })

            window.removeEventListener('online', () => {
                setOfflineMode(false)
            })
        }
    }, [])

    return (
        <OnlineStatusContext.Provider value={{offlineMode, setOfflineMode}}>
            {children}
        </OnlineStatusContext.Provider>
    )

}