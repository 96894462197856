import { Row, Col, Tab, Form } from "react-bootstrap";
import { Field } from "formik";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function CustomModuleTab({ editorState, setEditorState, eventKey, id }) {

  return (
    <Tab.Pane eventKey={eventKey}>
      <Form.Group as={Row} className="mb-3">
        <Col lg={4} md={6} sm={12}>
          <Form.Label>Özel Modül Başlığı:</Form.Label>
          <Field
            as={Form.Control}
            type="text"
            name={`settings.customModuleTitle${id}`}
            placeholder="Özel modül için başlık giriniz"
          />
        </Col>
      </Form.Group>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <div className="bg-white p-3 rounded">
            <Editor
              editorState={editorState}
              onEditorStateChange={(e) => setEditorState(e)}
              toolbar={{
                image: {
                  uploadEnabled: true
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
