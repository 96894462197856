import React, {useEffect, useRef, useState} from 'react'
import {useWindowSize} from "@react-hook/window-size";

export default function Marquee({id, speed, direction, onlyAnimateIfNeeded, alternatingTime, timingFunction, children}) {

    const marqueeRef = useRef(null)
    const innerContainerRef = useRef(null)
    const [windowWidth, windowHeight] = useWindowSize()
    const [animationDuration, setAnimationDuration] = useState()
    const [needToMarquee, setNeedToMarquee] = useState(true)

    useEffect(() => {
        if (direction && marqueeRef?.current && innerContainerRef?.current && id) {

            const containerW = innerContainerRef.current.offsetWidth
            const containerH = innerContainerRef.current.offsetHeight
            const marqueeW = marqueeRef.current.offsetWidth
            const marqueeH = marqueeRef.current.offsetHeight

            if (onlyAnimateIfNeeded) {
                if (direction === 'right-left') {
                    setNeedToMarquee(containerW < marqueeW)
                }
                else if (direction === 'up-down') {
                    setNeedToMarquee(containerH < marqueeH)
                }
            }

            if (needToMarquee) {
                const ss = window.document.styleSheets[0]
                for (let x = ss.cssRules.length - 1; x >= 0; x--) {
                    const rule = ss.cssRules[x]
                    if (rule.type === window.CSSRule.KEYFRAMES_RULE && rule.name === id) {
                        ss.deleteRule(x)
                        break
                    }
                }

                let duration

                switch (direction) {
                    case 'left':
                        ss.insertRule(`
                        @keyframes ${id} {
                          0% { transform: translateX(${containerW}px) }
                          100% { transform: translateX(-100%) }
                        }`, ss.cssRules.length)
                        duration = (containerW + marqueeW) / speed
                        break
                    case 'right':
                        ss.insertRule(`
                        @keyframes ${id} {
                          0% { transform: translateX(-100%) }
                          100% { transform: translateX(${containerW}px) }
                        }`, ss.cssRules.length)
                        duration = (containerW + marqueeW) / speed
                        break
                    case 'up':
                        ss.insertRule(`
                        @keyframes ${id} {
                          0% { transform: translateY(${containerH}px) }
                          100% { transform: translateY('-100%') }
                        }`, ss.cssRules.length)
                        duration = (containerH + marqueeH) / speed
                        break
                    case 'down':
                        ss.insertRule(`
                        @keyframes ${id} {
                          0% { transform: translateY(-100%) }
                          100% { transform: translateY(${containerH}px) }
                        }`, ss.cssRules.length)
                        duration = (containerH + marqueeH) / speed
                        break
                    case 'up-down':
                        ss.insertRule(`
                        @keyframes ${id} {
                          100% { transform: translateY(${containerH - marqueeH}px) }
                        }`, ss.cssRules.length)
                        duration = alternatingTime * 1000
                        break
                    case 'right-left':
                        ss.insertRule(`
                        @keyframes ${id} {
                          100% { transform: translateX(${containerW - marqueeW}px) }
                        }`, ss.cssRules.length)
                        duration = alternatingTime * 1000
                        break
                    default: break
                }
                setAnimationDuration(duration)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marqueeRef, speed, direction, innerContainerRef, windowWidth, windowHeight, id, onlyAnimateIfNeeded, needToMarquee])

    return (
        <div className="marquee">
            <div className="marquee-inner-container" ref={innerContainerRef}>
                <div ref={marqueeRef} className="marquee-content"
                     style={{
                         animationName: needToMarquee ? id : undefined,
                         animationDuration: needToMarquee ? `${animationDuration}ms` : undefined,
                         animationTimingFunction: timingFunction || 'linear',
                         animationDirection: ['up-down', 'right-left'].includes(direction) ? 'alternate' : 'normal',
                         width: ['up', 'down', 'up-down'].includes(direction) ? '100%' : 'auto',
                         height: ['left', 'right', 'right-left'].includes(direction) ? '100%' : 'auto',
                     }}>
                    {children}
                </div>
            </div>
        </div>
    )
}