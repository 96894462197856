import React, {useContext} from "react";
import {Card} from "react-bootstrap";
import {DatabaseContext} from "../providers/DatabaseProvider";
import draftToHtml from 'draftjs-to-html';
import star from "../assets/images/star.svg"
import Marquee from "./Marquee";

export default function CustomModule({id}) {

    const {DB} = useContext(DatabaseContext)

    return (
        <Card className="custom-module module d-flex flex-fill overflow-hidden">
            <Card.Header className="d-flex align-items-center p-2 purple">
                <img src={star} className="me-2" alt="Star" />{DB.settings[`customModuleTitle${id}`] || `Özel Modül ${id}`}
            </Card.Header>
            <Card.Body className="d-flex p-2 flex-fill flex-column overflow-hidden">
                <Marquee direction="up-down" id="customModuleContent" onlyAnimateIfNeeded
                         alternatingTime={DB.settings.flipperInterval}>
                    <div dangerouslySetInnerHTML={{__html: draftToHtml(DB.settings[`customModuleContent${id}`])}} />
                </Marquee>
            </Card.Body>
        </Card>
    )
}
