import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Row, Col } from "react-bootstrap";
import AwesomeSlider from "react-awesome-slider/dist";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import { FaUnlock, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { DatabaseContext } from "../providers/DatabaseProvider";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function Slider() {
  const { DB } = useContext(DatabaseContext);
  const [sliderPlaying, setSliderPlaying] = useState(true);
  const [mediaList, setMediaList] = useState([]);

  const fetchImages = async () => {
    const queryPermission = await DB.settings.sliderFolder.queryPermission({ mode: "read" });
    const requestPermission = await DB.settings.sliderFolder.requestPermission({ mode: "read" });

    if (queryPermission === "granted" || requestPermission === "granted") {
      const files = [];
      for await (const entry of DB.settings.sliderFolder.values()) {
        if (entry.kind === "file") {
          const fileHandle = await DB.settings.sliderFolder.getFileHandle(entry.name);
          const file = await fileHandle.getFile();
          if (file.type.startsWith("image") || file.type.startsWith("video")) {
            files.push({ source: URL.createObjectURL(file), type: file.type });
          }
        }
      }
      setMediaList(files);
    }
  };

  useEffect(() => {
    if (DB.settings.sliderFolder) {
      fetchImages().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DB.settings.sliderFolder]);

  return (
    <div className="slider-wrapper flex-fill">
      {mediaList.length > 0 ? (
        <AutoplaySlider
          play={sliderPlaying}
          interval={DB.settings.sliderInterval * 1000}
          cancelOnInteraction={false}
          fillParent
          buttons={false}
          bullets={false}
          animation={DB.settings.sliderAnimation}
        >
          {mediaList.map((media) => (
            <div className="slide" key={media.source}>
              {media.type.startsWith("video") ? (
                <video
                  src={media.source}
                  autoPlay
                  controls
                  loop={mediaList.length === 1}
                  style={{objectFit: DB.settings.sliderImageResizingMode}}
                  onPlay={() => setSliderPlaying(false)}
                  onEnded={() => {
                    if (mediaList.length > 1) {
                      setSliderPlaying(true);
                    }
                  }}
                />
              ) : (
                <img src={media.source} alt="" style={{objectFit: DB.settings.sliderImageResizingMode}} />
              )}
            </div>
          ))}
        </AutoplaySlider>
      ) : (
        <Card className="module h-100 overflow-hidden">
          {DB.settings.sliderFolder ? (
            <Card.Body className="module overflow-hidden">
              <Row>
                <Col>
                  <Alert variant="warning" className="text-center">
                    <h3>
                      Seçtiğiniz klasörde resim/video bulunamadı
                      <br />
                      veya klasöre erişim izni vermediniz.
                    </h3>
                    <br />
                    <i>
                      <FaInfoCircle /> Tarayıcı güvenlik ilkeleri sebebiyle uygulamayı her başlattığınızda tekrar erişim
                      izni vermeniz gerekmektedir.
                    </i>
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button variant="primary" className="d-flex align-items-center" onClick={() => fetchImages()}>
                    <FaUnlock className="me-2" />
                    Klasöre Tekrar Erişim İzni Ver
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          ) : (
            <Card.Body className="module overflow-hidden">
              <Row>
                <Col>
                  <Alert variant="warning" className="text-center">
                    <h3>Slayt olarak görüntülemek üzere herhangi bir klasöre erişim izni vermediniz.</h3>
                    <br />
                    <h3>
                      Lütfen <Link to="/settings/slider">Slayt Ayarları</Link> ekranından bir klasör seçiniz.
                    </h3>
                  </Alert>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      )}
      {/* <iframe src="https://www.meb.gov.tr" frameBorder="0" scrolling="no" title="iframePano" /> */}
    </div>
  );
}
