export const array0 = [0];
export const array01 = [0, 1];
export const array06 = [0, 1, 2, 3, 4, 5, 6];
export const array013 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
export const daysShortUppercase = ["PZT", "SAL", "ÇAR", "PER", "CUM", "CMT", "PAZ"];
export const daysLong = ["PAZARTESİ", "SALI", "ÇARŞAMBA", "PERŞEMBE", "CUMA", "CUMARTESİ", "PAZAR"];
export const dualEducationGroups = ["SABAHÇI", "ÖĞLENCİ"];
export const daysDualEducationShortUppercase = [
    "PZT (S)",
    "PZT (Ö)",
    "SAL (S)",
    "SAL (Ö)",
    "ÇAR (S)",
    "ÇAR (Ö)",
    "PER (S)",
    "PER (Ö)",
    "CUM (S)",
    "CUM (Ö)",
    "CMT (S)",
    "CMT (Ö)",
    "PAZ (S)",
    "PAZ (Ö)"
  ];