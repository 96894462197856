import * as Yup from "yup";
import DateTime from "./DayJs";

Yup.addMethod(Yup.array, "unique", function (propName, message) {
  return this.test("unique", message, function (list) {
    const mapper = (x) => x[propName];
    const set = [...new Set(list.map(mapper))];
    if (list.length !== set.length) {
      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
      return this.createError({
        path: `${this.path}.[${idx}]`,
        message: { [propName]: message }
      });
    }
    return true;
  });
});

const ValidationSchema = Yup.object({
  places: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().trim().required("Lütfen nöbet yeri ismini giriniz")
      })
    )
    .unique("name", "Aynı isimde nöbet yeri bulunamaz."),
  lessonTimes: Yup.array().of(
    Yup.array().of(
      Yup.object({
        start: Yup.string()
          .trim()
          .required("Lütfen ders başlangıç saatini giriniz.")
          .test(
            "is-greater-then-previous-end",
            "Başlangıç saati önceki dersin bitiş saatinden sonra olmalıdır.",
            function (value) {
              const splittedPath = this.path.split("][");
              const dayIndex = Number(splittedPath[0].split("[")[1]);
              const lessonIndex = Number(splittedPath[1].split("]")[0]);
              const { dualEducation, sameLessonTimesEveryday } = this.from[1].value.settings;
              const { lessonTimes } = this.from[1].value;
              if (sameLessonTimesEveryday) {
                if (dualEducation && dayIndex > 1) {
                  return true;
                }
                if (!dualEducation && dayIndex > 0) {
                  return true;
                }
              }
              if (dualEducation) {
                if (dayIndex % 2 === 1 && lessonIndex === 0) {
                  const morningLessonTimes = lessonTimes[dayIndex - 1];
                  return DateTime(value, "HH:mm").isAfter(
                    DateTime(morningLessonTimes[morningLessonTimes.length - 1].end, "HH:mm")
                  )
                    ? true
                    : this.createError({
                        message: "Başlangıç saati sabah derslerinin bitiş saatinden sonra olmalıdır."
                      });
                }
              }
              if (lessonIndex > 0) {
                return DateTime(value, "HH:mm").isAfter(DateTime(lessonTimes[dayIndex][lessonIndex - 1].end, "HH:mm"));
              }
              return true;
            }
          ),
        end: Yup.string()
          .trim()
          .required("Lütfen ders bitiş saatini giriniz.")
          .test("is-greater-than-start", "Bitiş saati başlangıç saatinden sonra olmalıdır.", function (value) {
            return DateTime(value, "HH:mm").isAfter(DateTime(this.from[0].value.start, "HH:mm"));
          })
      })
    )
  )
});

export default ValidationSchema;
