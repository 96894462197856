import { FaExclamationTriangle } from "react-icons/fa";
import { Button, Modal, Alert } from "react-bootstrap";
import toast from "cogo-toast";

export default function CloudUploadModal({ show, onDismiss, code }) {
  return (
    <Modal centered show={show} onHide={onDismiss}>
      <Modal.Header>
        <Modal.Title>Bulut Yedekleme</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          Yedeğiniz başarıyla buluta yüklendi. Aşağıdaki kodu hedef bilgisayarda girerek yedeğinizi geri
          yükleyebilirsiniz.
        </p>
        <h2
          className="cloud-export-code py-3 mb-4 bg-success text-white text-lg-center rounded"
          title="Kodu kopyalamak için tıklayınız."
          onClick={() => {
            navigator.clipboard.writeText(code).then(() => {
              toast.success("Kod kopyalandı");
            });
          }}
        >
          {code}
        </h2>
        <Alert variant="warning">
          <FaExclamationTriangle className="me-2" />
          Kod büyük/küçük harf duyarlıdır ve 3 gün sonra otomatik olarak silinir.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onDismiss}>
          Tamam
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
