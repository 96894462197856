import { Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FaSave, FaTimesCircle } from "react-icons/fa";
import VersionPopup from "./VersionPopup";
import Logo from "../../assets/images/logo.png";

export default function TopBar({ activeTab, isSubmitting }) {

  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect bg="dark" sticky="top" variant="dark" expand="lg" className="p-3">
      <Link to="/" className="navbar-brand d-flex align-items-center">
        <img
          src={Logo}
          alt="Logo"
          className="me-2"
          style={{
            height: 30
          }}
        />
        <strong>LCD Pano</strong>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto" activeKey={activeTab}>
          <Link to="/settings/general" className={`nav-link text-center ${activeTab === "general" ? "active" : ""}`}>
            Genel Ayarlar
          </Link>
          {/* <Link
            to="/settings/lesson-times"
            className={`nav-link text-center ${activeTab === "lesson-times" ? "active" : ""}`}
          >
            Ders Saatleri
          </Link>
          <Link
            to="/settings/lesson-program"
            className={`nav-link text-center ${activeTab === "lesson-program" ? "active" : ""}`}
          >
            Ders Programı
          </Link>
          <Link
            to="/settings/monitoring-tasks"
            className={`nav-link text-center ${activeTab === "monitoring-tasks" ? "active" : ""}`}
          >
            Nöbet Programı
          </Link> */}
          <Link to="/settings/slider" className={`nav-link text-center ${activeTab === "slider" ? "active" : ""}`}>
            Slayt Ayarları
          </Link>
          <Link
            to="/settings/custom-module-left-front"
            className={`nav-link text-center ${activeTab === "custom-module-left-front" ? "active" : ""}`}
          >
            Özel Modül (Sol Ön)
          </Link>
          <Link
            to="/settings/custom-module-left-back"
            className={`nav-link text-center ${activeTab === "custom-module-left-back" ? "active" : ""}`}
          >
            Özel Modül (Sol Arka)
          </Link>
          <Link
            to="/settings/custom-module-right-back"
            className={`nav-link text-center ${activeTab === "custom-module-right-back" ? "active" : ""}`}
          >
            Özel Modül (Sağ Arka)
          </Link>
          <Link
            to="/settings/import-export"
            className={`nav-link text-center ${activeTab === "import-export" ? "active" : ""}`}
          >
            Yedekle/Geri Yükle
          </Link>
        </Nav>
        <Navbar.Text className="me-3">
          <VersionPopup />
        </Navbar.Text>
        <Nav className="mb-3 mb-lg-0">
          <Button
            id="saveButton"
            variant="success"
            type="submit"
            disabled={isSubmitting}
            form="settingsForm"
            className="d-inline-flex align-items-center"
          >
            <FaSave className="me-2" />
            Kaydet
          </Button>
          <Button
            variant="danger"
            className="d-inline-flex align-items-center ms-md-0 ms-lg-3 mt-md-3 mt-lg-0"
            onClick={() => navigate("/")}
          >
            <FaTimesCircle className="me-2" />
            İptal
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
