import { Button, Modal } from "react-bootstrap";

export default function DeletePlaceModal({ show, onSubmit, onDismiss, item }) {
  return (
    <Modal centered show={show} onHide={onDismiss}>
      <Modal.Header>
        <Modal.Title>Nöbet Yeri Silme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item.name ? <strong>{item.name}</strong> : "Bu"} nöbet yerini silmek istediğinize emin misiniz?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onSubmit}>
          Evet
        </Button>
        <Button variant="secondary" onClick={onDismiss}>
          Hayır
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
