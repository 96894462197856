import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, Separator } from "react-contexify";
import { FaExpand, FaCompress, FaCog, FaWindowMinimize, FaSignOutAlt } from "react-icons/fa";
import isElectron from "is-electron";

let electron;

if (isElectron()) {
  electron = window.require("electron").remote;
}

export function handleFullscreenClick () {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    document.documentElement.requestFullscreen().then();
  }
}

export default function ContextMenu({ id }) {
  const navigate = useNavigate();

  const handleMinimizeClick = () => {
    electron.getCurrentWindow().minimize();
  };

  const handleExitClick = () => {
    electron.app.quit();
  };

  const handleSettingsClick = () => {
    navigate("/settings/general");
  };

  return (
    <Menu id={id}>
      <Item onClick={handleFullscreenClick}>
        {document.fullscreenElement ? (
          <>
            <FaCompress />
            <span className="ms-2">Tam Ekran'dan Çık</span>
          </>
        ) : (
          <>
            <FaExpand />
            <span className="ms-2">Tam Ekran</span>
          </>
        )}
      </Item>
      <Separator />
      <Item onClick={handleSettingsClick}>
        <FaCog />
        <span className="ms-2">Ayarlar</span>
      </Item>
      {isElectron() && (
        <>
          <Separator />
          <Item onClick={handleMinimizeClick}>
            <small>
              <FaWindowMinimize />
              <span className="ms-2">Küçült</span>
            </small>
          </Item>
          <Separator />
          <Item onClick={handleExitClick}>
            <small>
              <FaSignOutAlt />
              <span className="ms-2">Çıkış</span>
            </small>
          </Item>
        </>
      )}
    </Menu>
  );
}
