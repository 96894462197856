import React, {useState} from "react"
import useRealTimeInterval from "../hooks/useRealTimeInterval"

export const TimerContext = React.createContext(null)

export default function TimerProvider({children}) {

    const [tickSeconds, setTickSeconds] = useState(0)

    useRealTimeInterval(() => {
        setTickSeconds(tickSeconds + 1)
    }, 1000)

    return (
        <TimerContext.Provider value={{tickSeconds}}>
            {children}
        </TimerContext.Provider>
    )

}