import React, {useContext} from "react";
import {Card} from "react-bootstrap";
import Marquee from "./Marquee";
import {DatabaseContext} from "../providers/DatabaseProvider";

export default function MarqueeText() {

    const {DB} = useContext(DatabaseContext)

    return (
        <Card className="marquee-text d-flex flex-fill overflow-hidden">
            <Card.Body className="p-1 d-flex align-items-center">
                <Marquee direction="left" speed={DB.settings.marqueeSpeed * 0.1} id="marqueeText">
                    {DB.settings.marqueeText}
                </Marquee>
            </Card.Body>
        </Card>
    )
}
