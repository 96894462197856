import React, {useContext, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import weather from "../assets/images/weather.svg"
import {DatabaseContext} from "../providers/DatabaseProvider";
import {WeatherContext} from "../providers/WeatherProvider";
import {TimerContext} from "../providers/TimerProvider";

export default function Weather() {

    const {DB} = useContext(DatabaseContext)
    const {tickSeconds} = useContext(TimerContext)
    const [weatherInfo, setWeatherInfo] = useState()

    const {currentConditions, refreshCurrentConditions} = useContext(WeatherContext)

    useEffect(() => {
        if (currentConditions) {
            setWeatherInfo(currentConditions?.find(il => il.cityNumber === parseInt(DB.settings.weatherCity))
                .stations.find(m => m.stationName === DB.settings.weatherStation))
        }
    }, [currentConditions, DB.settings.weatherStation, DB.settings.weatherCity])

    useEffect(() => {
        const { activeModules } = DB.settings;
        if (activeModules.includes("trt-haber-weather") && tickSeconds % 900 === 0) {
            refreshCurrentConditions()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickSeconds, DB.settings.activeModules])


    return (
        <Card className="weather module d-flex">
            <Card.Header className="d-flex align-items-center p-2 blue">
                <img src={weather} className="me-2" alt="Weather" />Hava Durumu
            </Card.Header>
            <Card.Body className="p-0 d-flex justify-content-center align-items-center overflow-hidden">
                <div className="currents my-2 mx-3 flex-fill d-flex flex-column">
                    <span className="district">{DB.settings.weatherDisplayText}</span>
                    <span className="temperature">{parseFloat(weatherInfo?.temperature || "0").toFixed()}&deg;C</span>
                </div>
            </Card.Body>
        </Card>
    );
}
