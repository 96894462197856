import { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Form, Table, Card, ListGroup } from "react-bootstrap";
import { FaTrash, FaEdit, FaCaretLeft, FaBan } from "react-icons/fa";
import { AiOutlineClear, AiOutlineFileExcel } from "react-icons/ai";
import { Field, FieldArray } from "formik";
import showModal from "@prezly/react-promise-modal";
import { daysShortUppercase, array06 } from "../../providers/Constants";
import DeleteClassroomModal from "../../components/settings/DeleteClassroomModal";
import EditClassroomModal from "../../components/settings/EditClassroomModal";

export default function LessonProgramTab({ formik, activeTab }) {
  const {
    values,
    values: { settings, classRooms, lessonTimes },
    setFieldValue,
    setValues
  } = formik;
  const [activeClassRoom, setActiveClassRoom] = useState(0);
  const [isClassroomExists, setIsClassroomExists] = useState(false);
  const [newClassRoomName, setNewClassRoomName] = useState("");

  const arrayFromMaxLessonTimes = () => {
    if (settings.dualEducation) {
      if (settings.sameLessonTimesEveryday) {
        return [...Array(Math.max(...lessonTimes.slice(0, 2).map((lt) => lt.length))).keys()];
      }
      return [...Array(Math.max(...lessonTimes.map((lt) => lt.length))).keys()];
    } else {
      if (settings.sameLessonTimesEveryday) {
        return [...Array(lessonTimes[0].length).keys()];
      }
      return [...Array(Math.max(...lessonTimes.slice(0, 7).map((lt) => lt.length))).keys()];
    }
  };

  const lessonProgramInputCheck = (dayIndex) => {
    if (settings.dualEducation) {
      if (settings.sameLessonTimesEveryday) {
        return classRooms[activeClassRoom].isAfternoon ? 1 : 0;
      }
      return classRooms[activeClassRoom].isAfternoon ? dayIndex * 2 + 1 : dayIndex * 2;
    } else {
      if (settings.sameLessonTimesEveryday) {
        return 0;
      }
      return dayIndex;
    }
  };

  const deleteClassRoom = (item, index) => {
    showModal(({ show, onSubmit, onDismiss }) => (
      <DeleteClassroomModal item={item} show={show} onSubmit={onSubmit} onDismiss={onDismiss} />
    )).then((result) => {
      if (result) {
        if (index === classRooms.length - 1) {
          setActiveClassRoom(activeClassRoom - 1);
        }
        classRooms.splice(index, 1);
        setValues({ ...values });
      }
    });
  };

  const editClassRoom = (item) => {
    showModal(({ show, onSubmit, onDismiss }) => (
      <EditClassroomModal item={item} show={show} onSubmit={onSubmit} onDismiss={onDismiss} />
    )).then((result) => {
      if (result) {
        item.name = result;
        setValues({ ...values });
      }
    });
  };

  const pasteFromExcelToProgram = () => {
    navigator.clipboard.readText().then((copied) => {
      copied
        .trim()
        .split(/\r\n/)
        .slice(0, 7)
        .forEach((line, i) => {
          const parsedLine = line.split(/\t/).slice(0, lessonTimes[i].length);
          classRooms[activeClassRoom].program[i].fill("").splice(0, parsedLine.length, ...parsedLine);
        });
      setValues({ ...values });
    });
  };

  const clearProgram = () => {
    classRooms[activeClassRoom].program.forEach((p) => p.fill(""));
    setValues({ ...values });
  };

  useEffect(() => {
    if (activeTab === "lesson-program") {
      setIsClassroomExists(classRooms.some((c) => c.name === newClassRoomName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClassRoomName, classRooms]);

  return (
    <Tab.Pane eventKey="lesson-program">
      <Row>
        <Col md={12} lg={2} className="mb-3">
          <ListGroup>
            {classRooms.map((c, i) => (
              <ListGroup.Item
                key={c.name}
                action
                as="div"
                active={classRooms[activeClassRoom].name === c.name}
                className="classroom-list-item"
                onClick={() => {
                  setActiveClassRoom(classRooms.findIndex((i) => i.name === c.name));
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>{c.name}</div>
                  <div className="classroom-list-item-actions d-flex align-items-center">
                    {settings.dualEducation && (
                      <Field
                        as={Form.Check}
                        type="checkbox"
                        id={`classRooms${i}_cbAfternoon`}
                        name={`classRooms[${i}].isAfternoon`}
                        label="Öğlenci"
                        className="me-2"
                        value="true"
                        onChange={(e) => {
                          setFieldValue(`classRooms[${i}].isAfternoon`, e.target.checked);
                        }}
                        checked={classRooms[i].isAfternoon}
                      />
                    )}
                    <Button variant="warning" size="sm" className="p-1 me-2" onClick={() => editClassRoom(c)}>
                      <FaEdit />
                    </Button>
                    <Button variant="danger" size="sm" className="p-1" onClick={() => deleteClassRoom(c, i)}>
                      <FaTrash />
                    </Button>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col>
          <Table size="sm" className="mb-5" striped bordered hover responsive>
            <thead>
              <tr>
                <th />
                {arrayFromMaxLessonTimes().map((i) => (
                  <th key={i} className="text-center">
                    {i + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name="classRooms"
                render={() =>
                  array06.map((dayIndex) => (
                    <tr key={daysShortUppercase[dayIndex]}>
                      <th className="align-middle p-2">{daysShortUppercase[dayIndex]}</th>
                      {arrayFromMaxLessonTimes().map((timeIndex) => (
                        <td key={timeIndex} className="text-center">
                          {timeIndex < lessonTimes[lessonProgramInputCheck(dayIndex)].length ? (
                            <Field
                              as={Form.Control}
                              type="text"
                              maxLength={50}
                              value={classRooms[activeClassRoom].program[dayIndex]?.[timeIndex] ?? ""}
                              name={`classRooms[${activeClassRoom}].program[${dayIndex}][${timeIndex}]`}
                            />
                          ) : (
                            <FaBan
                              className="text-danger"
                              title="Seçtiğiniz ders saatlerine göre bu saatte ders bulunmamaktadır."
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                }
              />
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={settings.lessonsPerDay + 1}>
                  <Button
                    variant="success"
                    className="d-inline-flex align-items-center me-2"
                    onClick={pasteFromExcelToProgram}
                  >
                    <AiOutlineFileExcel />
                    <span className="ms-2">Excel'den Yapıştır</span>
                  </Button>
                  <Button variant="danger" className="d-inline-flex align-items-center me-2" onClick={clearProgram}>
                    <AiOutlineClear />
                    <span className="ms-2">Temizle</span>
                  </Button>
                </th>
              </tr>
            </tfoot>
          </Table>
          <Row>
            <Col md={4} sm={12}>
              <Card>
                <Card.Header>
                  <strong>Yeni Sınıf Ekle</strong>
                </Card.Header>
                <Card.Body>
                  <Form.Control
                    type="text"
                    placeholder="Sınıf Adı"
                    maxLength={8}
                    value={newClassRoomName}
                    onChange={(e) => setNewClassRoomName(e.target.value)}
                    isInvalid={isClassroomExists}
                  />
                  {isClassroomExists ? (
                    <Form.Control.Feedback type="invalid">Bu sınıf zaten mevcut</Form.Control.Feedback>
                  ) : null}
                </Card.Body>
                <Card.Footer>
                  <Button
                    variant="success"
                    disabled={!newClassRoomName.trim() || isClassroomExists}
                    className="d-flex align-items-center"
                    onClick={() => {
                      if (!isClassroomExists) {
                        setFieldValue("classRooms", [
                          ...classRooms,
                          {
                            name: newClassRoomName,
                            program: array06.map(() => arrayFromMaxLessonTimes().map(() => ""))
                          }
                        ]);
                        setNewClassRoomName("");
                      }
                    }}
                  >
                    <FaCaretLeft className="me-2" />
                    Ekle
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Tab.Pane>
  );
}
