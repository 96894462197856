import React, {useContext} from "react";
import {Card} from "react-bootstrap";
import {DatabaseContext} from "../providers/DatabaseProvider";

export default function SchoolName() {
    const {DB} = useContext(DatabaseContext)

    return (
        <Card className="school-name d-flex flex-fill p-0">
            <Card.Body className="p-0 d-flex justify-content-center align-items-center">
                <span>{DB.settings.schoolName}</span>
            </Card.Body>
        </Card>
    )
}
