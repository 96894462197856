import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function EditClassroomModal({ show, onSubmit, onDismiss, item }) {
  const [name, setName] = useState(item.name || "");
  return (
    <Modal centered show={show} onHide={onDismiss}>
      <Modal.Header>
        <Modal.Title>Sınıf Adı Değiştirme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Sınıf Adı"
          value={name}
          maxLength={8}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onSubmit(name)} disabled={!name.trim()}>
          Tamam
        </Button>
        <Button variant="secondary" onClick={onDismiss}>
          İptal
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
