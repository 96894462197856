import React from "react"
import {Card} from "react-bootstrap"
import DateTime from "../providers/DayJs"

export default function DateAndTime() {
    return (
        <Card className="date-and-time d-flex flex-fill p-0">
            <Card.Body className="p-0 d-flex align-items-center justify-content-around">
                <span className="date">{DateTime().format("ddd, DD MMM YYYY")}</span>
                <span className="time">{DateTime().format("HH:mm")}</span>
            </Card.Body>
        </Card>
    )
}
