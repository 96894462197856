import { FaMinusCircle } from "react-icons/fa";
import { Col, Button, Form, Card } from "react-bootstrap";
import { Field } from "formik";

export default function LessonTimeBox({
  dayIndex,
  lessonIndex,
  startIsInvalid,
  endIsInvalid,
  headerText,
  showRemove,
  onRemove
}) {
  return (
    <Col xl={2} lg={3} sm={4} xs={6}>
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <span>{headerText}</span>
            {showRemove && onRemove && (
              <Button variant="outline-danger" size="sm" onClick={onRemove} title="Ders Saati Kaldır">
                <FaMinusCircle />
              </Button>
            )}
          </div>
          <div className="my-2">
            <div className="text-center">
              <Field
                as={Form.Control}
                type="time"
                name={`lessonTimes[${dayIndex}][${lessonIndex}].start`}
                isInvalid={startIsInvalid}
              />
            </div>
            {startIsInvalid && (
              <div className="text-danger mb-3">
                <small>{startIsInvalid}</small>
              </div>
            )}
          </div>
          <div>
            <div className="text-center">
              <Field
                as={Form.Control}
                type="time"
                name={`lessonTimes[${dayIndex}][${lessonIndex}].end`}
                isInvalid={endIsInvalid}
              />
            </div>
            {endIsInvalid && (
              <div className="text-danger mb-3">
                <small>{endIsInvalid}</small>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
