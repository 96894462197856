import { Row, Col, Button, Tab, Form, Tabs } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Field, FieldArray } from "formik";
import {
  daysLong,
  dualEducationGroups,
  daysDualEducationShortUppercase,
  array0,
  array01,
  array06,
  array013
} from "../../providers/Constants";
import LessonTimeBox from "../../components/settings/LessonTimeBox";

export default function LessonTimesTab({ activeLessonTab, setActiveLessonTab, formik }) {
  const {
    values: {
      settings: { sameLessonTimesEveryday, dualEducation },
      lessonTimes
    },
    errors,
    setFieldValue
  } = formik;

  const getLessonTimesTabTitle = (day) => {
    if (sameLessonTimesEveryday) {
      return dualEducation ? dualEducationGroups[day] : daysLong[day];
    } else {
      return dualEducation ? daysDualEducationShortUppercase[day] : daysLong[day];
    }
  };

  const lessonTimesInputSourceArray = () => {
    if (dualEducation) {
      return sameLessonTimesEveryday ? array01 : array013;
    } else {
      return sameLessonTimesEveryday ? array0 : array06;
    }
  };

  return (
    <Tab.Pane eventKey="lesson-times">
      <Row className="mb-3">
        <Col>
          <h5>Ders Başlangıç-Bitiş Saatleri</h5>
        </Col>
      </Row>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label className="me-3">Öğretim Şekli:</Form.Label>
          <Field
            as={Form.Check}
            type="radio"
            inline
            id="educationType1"
            name="settings.dualEducation"
            label="Normal Öğretim"
            value="normal"
            onChange={(e) => setFieldValue("settings.dualEducation", e.target.value === "dual")}
            checked={dualEducation === false}
          />
          <Field
            as={Form.Check}
            type="radio"
            inline
            id="educationType2"
            name="settings.dualEducation"
            value="dual"
            label="İkili Öğretim"
            onChange={(e) => setFieldValue("settings.dualEducation", e.target.value === "dual")}
            checked={dualEducation === true}
          />
        </Col>
      </Form.Group>
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <Field
            as={Form.Check}
            type="checkbox"
            inline
            id="sameLessonTimesEveryday"
            name="settings.sameLessonTimesEveryday"
            label="Her gün için aynı programı uygula"
          />
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="lessonTimesTab0"
        id="lessonTimesTabs"
        activeKey={activeLessonTab}
        onSelect={setActiveLessonTab}
      >
        {lessonTimesInputSourceArray().map((day) => (
          <Tab
            eventKey={`lessonTimesTab${day}`}
            title={getLessonTimesTabTitle(day)}
            key={`lessonTimes${day}`}
            tabClassName={sameLessonTimesEveryday && !dualEducation ? "d-none" : ""}
          >
            <div className="lesson-times-tab-content-wrapper rounded-bottom p-3">
              <Row>
                <FieldArray
                  name={`lessonTimes[${day}]`}
                  render={(arrayHelpers) => (
                    <>
                      {lessonTimes[day]?.map((lessonTime, li) => (
                        <LessonTimeBox
                          dayIndex={day}
                          lessonIndex={li}
                          key={`lesson-${day}-${li}`}
                          headerText={`${li + 1}. ders`}
                          showRemove={li === lessonTimes[day].length - 1 && lessonTimes[day].length > 1}
                          onRemove={() => arrayHelpers.pop()}
                          startIsInvalid={errors?.lessonTimes?.[day]?.[li]?.start}
                          endIsInvalid={errors?.lessonTimes?.[day]?.[li]?.end}
                        />
                      ))}
                      <Col xl={2} lg={3} sm={4} xs={6} className="d-flex align-items-center">
                        <Button
                          variant="outline-success"
                          title="Ders Saati Ekle"
                          size="lg"
                          onClick={() =>
                            arrayHelpers.push({
                              start: "",
                              end: ""
                            })
                          }
                        >
                          <FaPlusCircle />
                        </Button>
                      </Col>
                    </>
                  )}
                />
              </Row>
            </div>
          </Tab>
        ))}
      </Tabs>
    </Tab.Pane>
  );
}
