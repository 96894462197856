import React, { useContext, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import { DatabaseContext } from "../providers/DatabaseProvider";
import SchoolName from "../components/SchoolName";
import DateAndTime from "../components/DateAndTime";
import HallMonitors from "../components/HallMonitors";
import MarqueeText from "../components/MarqueeText";
import Slider from "../components/Slider";
import Weather from "../components/Weather";
import TRTHaber from "../components/TRTHaber";
import TimeTable from "../components/TimeTable";
import CustomModule from "../components/CustomModule";
import Logo from "../assets/images/logo.png";
import TRFlag from "../assets/images/tr-flag.svg";
import { OnlineStatusContext } from "../providers/OnlineStatusProvider";
import { TimerContext } from "../providers/TimerProvider";
import ContextMenu, { handleFullscreenClick } from "../components/ContextMenu";

export default function App() {
  const { show } = useContextMenu({ id: "context-menu" });
  const { DB } = useContext(DatabaseContext);
  const { offlineMode } = useContext(OnlineStatusContext);
  const { tickSeconds } = useContext(TimerContext);

  const [leftFlip, setLeftFlip] = useState(true);
  const [rightFlip, setRightFlip] = useState(true);

  const [leftColumnActive, setLeftColumnActive] = useState(false);
  const [rightColumnActive, setRightColumnActive] = useState(false);

  useEffect(() => {
    if (DB.settings && tickSeconds % DB.settings.flipperInterval === 0) {
      const { activeModules } = DB.settings;

      if (activeModules.includes("hall-monitors") && activeModules.includes("custom-module")) {
        setLeftColumnActive(true);
        setLeftFlip((f) => !f);
      } else if (activeModules.includes("custom-module")) {
        setLeftColumnActive(true);
        setLeftFlip(true);
      } else if (activeModules.includes("hall-monitors")) {
        setLeftColumnActive(true);
        setLeftFlip(false);
      } else {
        setLeftColumnActive(false);
      }

      if (offlineMode && activeModules.includes("time-table")) {
        setRightColumnActive(true);
        setRightFlip(true);
      } else if (offlineMode) {
        setRightColumnActive(false);
      } else {
        if (activeModules.includes("trt-haber-weather") && activeModules.includes("time-table")) {
          setRightColumnActive(true);
          setRightFlip((f) => !f);
        } else if (activeModules.includes("time-table")) {
          setRightColumnActive(true);
          setRightFlip(true);
        } else if (activeModules.includes("trt-haber-weather")) {
          setRightColumnActive(true);
          setRightFlip(false);
        } else {
          setRightColumnActive(false);
        }
      }
    }
  }, [tickSeconds, DB, offlineMode]);

  return (
    <>
      <div className="grid-container" onContextMenu={(event) => show({ event })} onDoubleClick={handleFullscreenClick}>
        <div className="top-left">
          <SchoolName />
        </div>
        <div className="top-left-2">
        <img src={TRFlag} className="logo" alt="Flag" />
        </div>
        <div className="top-center">
          <img src={Logo} className="logo" alt="Logo" />
        </div>
        <div className="top-right">
          <DateAndTime />
        </div>
        <div className="middle-left" style={{ display: leftColumnActive ? "flex" : "none" }}>
          <div className="flip-container">
            <div className={`flip to-left ${leftFlip ? "forward" : "reverse"}`}>
              <div className="front face">
                <CustomModule id="LeftFront" />
              </div>
              <div className="back face">
                <CustomModule id="LeftBack" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="middle-center"
          style={{
            gridRowStart: 2,
            gridColumnStart: leftColumnActive ? 2 : 1,
            gridRowEnd: 3,
            gridColumnEnd: rightColumnActive ? 5 : 6
          }}
        >
          <Slider />
        </div>
        <div className="middle-right" style={{ display: rightColumnActive ? "flex" : "none" }}>
          <div className="flip-container">
            <div className={`flip to-right ${rightFlip ? "forward" : "reverse"}`}>
              <div className="front face">
                <Weather />
                <TRTHaber />
              </div>
              <div className="back face">
                <CustomModule id="RightBack" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="footer"
          style={{
            gridRowStart: 3,
            gridColumnStart: 1,
            gridRowEnd: 4,
            gridColumnEnd: rightColumnActive ? 5 : 6
          }}
        >
          <MarqueeText />
        </div>
      </div>
      <div className="orientation-warning p-3">
        <img className="mb-5" src={Logo} alt="Logo" />
        <h1>Lütfen ekranınızı yatay konuma getiriniz.</h1>
      </div>
      <ContextMenu id="context-menu" />
    </>
  );
}
