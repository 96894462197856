import { useEffect, useLayoutEffect, useRef } from 'react'

export default function useRealTimeInterval(callback, delay) {
    const savedCallback = useRef(callback)

    useLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }

        let interval = null
        const timeOut = setTimeout(() => {
            interval = setInterval(savedCallback.current(), delay)
        },1000 - new Date().getMilliseconds())

        return () => {
            clearInterval(interval)
            clearTimeout(timeOut)
        }
    }, [callback, delay])
}