import * as DateTime from "dayjs"
import weekday from "dayjs/plugin/weekday"
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/tr"
DateTime.locale("tr")
DateTime.extend(weekday)
DateTime.extend(relativeTime)
DateTime.extend(isSameOrAfter)
DateTime.extend(isSameOrBefore)
DateTime.extend(isBetween)
DateTime.extend(customParseFormat)

export default DateTime